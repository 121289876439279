export let Message =
{
    Authorized :401,
    //Client error responses
    BadRequest:400,
    Forbidden:403,
    NotFound:404 ,//Not Found
    MethodeNotAllowed:405, //Method Not Allowed
    NotAcceptable:406,
    RequestTimeOut:408,
    //Server error responses
    internalServerError:500,
    NotImplemented:501,
    ServiceUnavailable:503,
    FieldRequired:'This field is required.',
    fileUploadMsg:'File is missing. Please upload a file.',
    EmailCountMsg:'Only 5 notification email IDs are allowed',
    EmailAddressValidMsg:'Email Address is not valid.',
    EmailDuplicate:'Email ID already exists.',
    ProjectCodeLength:'Project Code can only be of 8 characters.',
    passwordNotMatch:"Password doesn't match.",
    passwordValidationMsg:'Passwords must be: 9+ characters, with 1 lowercase,<br>1 uppercase, 1 digit, and 1 special character (#$^+=!*()@%&).',

    //
    //CSV ReportsName
    Vendor:'Vendor_',
    Reports:'Reports_',
    EndLink:'Vendor_endLinks_',
    StartLink:'Link_management_',
    MassTemplate:'DataUpdate_',

    //File Upload MSg
    FileUploadVendorNote:'Must include Surveylink column',
    FileUploadVendorEditNote:'Must include columns ( "refid1","Surveylink")',
    FileUploadManageEndLinkNote:'.CSV file with columns including "refid1","ScreenedURL","CompleteURL","QuotafullURL", "QualityURL" to be uploaded. <br>  End Link Template for this vendor can be downloaded from the quick actions (download icon) in the Vendor list.',
// Add by Sachin
    //resqscore
    resqInOrganic:'InOrganic',
    resqLeastOrganic:'LeastOrganic',
    resqModeratlyOrganic:'ModeratelyOrganic',
    resqOrganic:'Organic',
    resqTotal:'Total',
    // data0to50:'0-50',
    // data51to75:'51-75',
    // data76to100:'76-100',
    // dataTotal:'Total',

    //DFPErrorMessage
    CountryError:'Please select at least one Country',
    DeviceError:'Please select at least one Device',
    IPError:'Please enter the days for IP Restriction',
    DFPError:'Looks like you have not set any fields for DFP to function. Please retry.',
//Till Here
    //DFPresQMessage
    DFPresQMessage:'ResQ is an abbreviation used for Respondent Quality. This index can be set between Inorganic, Least Organic, Moderate Organic & Organic.',

    //Catcha Error Message
    CaptchaNotMatched:'Captcha did not  match.',
    CaptchaMatched:'Captcha has matched.'

}
